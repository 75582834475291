import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1080.000000 1062.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1062.000000) scale(0.100000,-0.100000)">
		<path d="M5035 9846 c-53 -24 -95 -85 -95 -138 0 -12 9 -40 20 -62 70 -144
280 -96 280 64 0 58 -28 103 -79 130 -46 23 -83 25 -126 6z"/>
<path d="M4602 9755 c-96 -83 -50 -235 76 -252 32 -4 50 0 85 20 132 78 80
267 -74 267 -38 0 -52 -6 -87 -35z"/>
<path d="M4200 9653 c-102 -53 -106 -193 -8 -253 86 -52 201 5 215 107 15 111
-108 197 -207 146z"/>
<path d="M3785 9476 c-111 -50 -128 -171 -35 -250 25 -21 42 -26 85 -26 59 0
102 27 130 81 35 66 3 154 -68 188 -50 24 -70 25 -112 7z"/>
<path d="M3334 9252 c-71 -45 -93 -120 -56 -192 50 -99 183 -106 248 -11 41
60 29 135 -30 190 -36 34 -118 40 -162 13z"/>
<path d="M3798 9148 c-66 -38 -89 -125 -51 -190 43 -74 134 -100 196 -56 48
34 54 41 68 86 10 35 9 48 -5 86 -33 87 -128 121 -208 74z"/>
<path d="M2895 8954 c-59 -21 -98 -83 -92 -146 4 -48 34 -96 72 -118 29 -16
96 -16 130 -1 53 22 80 68 80 131 0 67 -28 110 -88 134 -44 19 -50 19 -102 0z"/>
<path d="M3315 8865 c-118 -59 -90 -246 40 -271 69 -13 156 42 170 108 27 121
-99 218 -210 163z"/>
<path d="M3705 8753 c-32 -8 -84 -64 -95 -102 -21 -77 40 -167 119 -178 137
-18 222 146 124 239 -44 42 -90 55 -148 41z"/>
<path d="M2520 8585 c-19 -8 -46 -31 -58 -50 -70 -101 -3 -225 121 -225 45 0
53 4 93 44 39 39 44 48 44 90 0 26 -7 60 -15 76 -33 63 -119 93 -185 65z"/>
<path d="M2958 8557 c-117 -52 -131 -193 -26 -257 37 -22 107 -26 141 -8 104
54 102 205 -4 258 -43 22 -71 24 -111 7z"/>
<path d="M3320 8438 c-105 -29 -140 -160 -63 -240 77 -80 207 -45 243 65 32
102 -72 205 -180 175z"/>
<path d="M5068 8343 c-15 -32 -57 -123 -94 -203 -36 -80 -105 -226 -151 -325
-47 -99 -96 -202 -108 -230 -12 -27 -39 -86 -60 -130 -20 -44 -63 -136 -95
-205 -32 -69 -109 -233 -170 -365 -62 -132 -139 -296 -170 -365 -32 -69 -89
-192 -128 -275 -38 -82 -84 -181 -102 -220 -18 -38 -51 -108 -73 -155 -21 -47
-54 -116 -72 -155 -18 -38 -46 -99 -63 -135 -57 -122 -104 -225 -157 -340 -29
-64 -74 -160 -100 -215 -26 -55 -76 -161 -110 -235 -34 -74 -78 -169 -97 -210
-65 -138 -219 -468 -315 -675 -36 -77 -78 -167 -93 -200 -15 -33 -58 -125 -95
-205 -37 -80 -103 -219 -145 -310 -43 -91 -89 -190 -103 -220 -13 -30 -40 -89
-60 -130 -64 -137 -97 -213 -94 -217 2 -2 75 8 163 22 87 14 231 36 319 50
179 27 254 41 260 46 4 5 180 351 520 1024 158 314 349 692 425 840 76 149
278 549 450 890 374 743 509 1007 520 1019 5 5 15 -7 23 -25 8 -19 134 -275
280 -569 145 -294 323 -654 395 -800 71 -146 216 -440 322 -655 408 -828 514
-1043 704 -1430 108 -220 203 -411 210 -425 14 -23 22 -26 113 -32 136 -10
527 -26 532 -22 4 3 -31 88 -105 254 -25 58 -59 134 -74 170 -16 36 -46 106
-68 155 -58 132 -229 526 -344 790 -55 127 -111 254 -124 283 -13 29 -24 55
-24 57 0 2 -11 28 -24 57 -28 60 -497 1134 -567 1298 -15 36 -46 108 -69 160
-23 52 -55 124 -70 160 -15 36 -46 106 -68 155 -21 50 -84 194 -139 320 -55
127 -123 282 -151 345 -28 63 -105 241 -172 395 -67 154 -130 298 -140 320
-10 22 -46 105 -80 185 -34 80 -70 163 -80 185 -10 22 -59 135 -110 250 -130
299 -133 305 -140 305 -3 0 -18 -26 -32 -57z"/>
<path d="M2204 8181 c-49 -30 -76 -75 -76 -126 0 -81 53 -136 136 -143 44 -3
56 0 92 27 80 59 80 158 2 225 -49 42 -103 48 -154 17z"/>
<path d="M2560 8181 c-80 -42 -107 -147 -54 -211 68 -80 177 -81 234 0 54 76
29 169 -59 214 -40 21 -79 20 -121 -3z"/>
<path d="M2961 8047 c-13 -6 -38 -29 -54 -49 -56 -69 -31 -170 53 -213 62 -32
155 -3 187 58 22 42 22 115 -1 149 -39 60 -126 86 -185 55z"/>
<path d="M2328 7750 c-63 -52 -73 -127 -25 -197 21 -31 79 -63 114 -63 9 0 36
9 59 21 142 68 96 269 -61 269 -41 0 -57 -5 -87 -30z"/>
<path d="M1970 7752 c-93 -47 -107 -166 -27 -240 36 -34 121 -40 165 -13 68
44 89 118 52 190 -37 73 -117 100 -190 63z"/>
<path d="M2685 7611 c-52 -31 -68 -58 -72 -114 -5 -61 18 -105 69 -137 86 -52
200 8 214 112 14 112 -116 198 -211 139z"/>
<path d="M2148 7316 c-135 -49 -127 -239 12 -276 119 -32 226 109 158 210 -45
66 -105 89 -170 66z"/>
<path d="M1722 7274 c-49 -42 -67 -91 -52 -144 36 -124 185 -148 264 -43 26
35 26 115 -1 151 -33 45 -81 72 -128 72 -33 0 -50 -7 -83 -36z"/>
<path d="M2482 7139 c-57 -28 -86 -84 -79 -148 4 -38 12 -52 47 -83 37 -33 49
-38 91 -38 89 0 140 47 146 134 5 64 -19 106 -76 136 -51 25 -75 25 -129 -1z"/>
<path d="M1962 6719 c-66 -43 -84 -143 -36 -206 62 -82 159 -84 227 -4 36 43
42 92 18 146 -36 81 -135 111 -209 64z"/>
<path d="M1601 6714 c-83 -35 -115 -129 -71 -202 77 -126 270 -77 270 69 0 55
-34 109 -83 133 -43 20 -71 20 -116 0z"/>
<path d="M2341 6538 c-54 -37 -76 -73 -76 -126 0 -106 111 -174 208 -127 45
22 69 60 75 115 8 83 -43 147 -124 156 -41 5 -55 2 -83 -18z"/>
<path d="M1910 6203 c-36 -25 -45 -35 -65 -73 -34 -65 6 -157 82 -186 101 -39
193 30 193 143 -1 50 -18 80 -70 116 -32 22 -108 22 -140 0z"/>
<path d="M1550 6199 c-55 -22 -100 -85 -100 -139 0 -88 97 -164 179 -140 143
41 147 240 6 279 -47 13 -52 13 -85 0z"/>
<path d="M2281 6011 c-67 -67 -67 -137 -2 -202 50 -50 98 -59 157 -30 53 25
84 72 84 124 0 89 -59 147 -149 147 -46 0 -55 -4 -90 -39z"/>
<path d="M1912 5680 c-98 -60 -94 -197 8 -251 66 -35 143 -14 188 51 81 120
-71 276 -196 200z"/>
<path d="M1557 5675 c-46 -16 -64 -30 -83 -66 -40 -78 4 -183 85 -208 124 -37
239 105 169 209 -45 65 -104 88 -171 65z"/>
<path d="M2308 5512 c-15 -9 -36 -33 -47 -53 -69 -122 52 -261 178 -204 107
48 119 187 22 252 -41 27 -112 30 -153 5z"/>
<path d="M1673 5189 c-41 -12 -90 -72 -98 -119 -16 -96 81 -185 176 -160 85
23 134 112 104 188 -20 49 -52 77 -102 91 -21 6 -40 11 -43 10 -3 0 -19 -5
-37 -10z"/>
<path d="M2002 5134 c-82 -64 -83 -160 -2 -227 45 -38 90 -44 144 -17 106 51
116 187 19 251 -43 29 -120 25 -161 -7z"/>
<path d="M2430 5021 c-100 -53 -96 -206 7 -255 94 -45 204 23 205 127 2 114
-111 182 -212 128z"/>
<path d="M4392 4649 c-101 -198 -182 -362 -179 -365 2 -2 385 -3 851 -2 l847
3 -156 315 c-86 173 -167 335 -180 360 l-24 45 -488 3 -488 2 -183 -361z"/>
<path d="M2174 4680 c-33 -13 -82 -77 -90 -114 -8 -47 26 -118 70 -146 106
-65 243 34 216 155 -19 84 -117 137 -196 105z"/>
<path d="M1758 4641 c-72 -61 -75 -154 -8 -215 51 -46 95 -54 154 -28 53 24
86 70 86 123 0 89 -49 141 -139 147 -51 3 -59 0 -93 -27z"/>
<path d="M2613 4498 c-37 -27 -60 -63 -63 -98 -6 -67 0 -84 45 -127 38 -36 52
-43 86 -43 60 0 114 34 141 88 19 40 20 50 9 90 -25 96 -141 144 -218 90z"/>
<path d="M2440 4155 c-76 -33 -106 -135 -61 -209 28 -45 67 -66 121 -66 84 0
144 59 144 142 0 104 -108 174 -204 133z"/>
<path d="M2042 4130 c-33 -20 -71 -87 -72 -124 0 -40 39 -102 79 -124 51 -29
98 -28 144 3 47 32 67 71 67 130 0 39 -5 52 -38 87 -35 37 -45 42 -93 45 -42
3 -61 0 -87 -17z"/>
<path d="M2360 3686 c-73 -23 -110 -68 -110 -133 0 -144 160 -201 254 -91 29
33 36 50 36 82 0 94 -95 169 -180 142z"/>
<path d="M3615 2983 c-117 -30 -142 -193 -40 -262 93 -63 225 10 225 124 0 87
-98 160 -185 138z"/>
<path d="M3992 2773 c-56 -27 -82 -67 -82 -125 0 -28 7 -62 16 -77 52 -88 184
-96 242 -13 53 73 28 173 -52 211 -51 25 -80 26 -124 4z"/>
<path d="M3685 2576 c-52 -23 -78 -60 -82 -118 -3 -41 1 -61 16 -86 59 -92
187 -95 247 -4 26 39 24 119 -4 159 -40 56 -114 77 -177 49z"/>
<path d="M4173 2360 c-110 -56 -112 -203 -5 -259 37 -19 111 -16 141 7 92 68
92 176 1 244 -40 29 -89 32 -137 8z"/>
<path d="M4763 2250 c-133 -29 -165 -182 -55 -265 34 -25 118 -26 156 -2 69
46 87 151 35 214 -30 38 -93 62 -136 53z"/>
<path d="M2480 1237 c0 -10 -212 -589 -245 -669 -6 -16 -1 -18 48 -18 l55 0
24 73 25 72 140 3 140 3 11 -28 c6 -16 18 -49 28 -75 l17 -48 49 0 c50 0 50 0
43 28 -13 55 -240 657 -250 664 -18 13 -85 9 -85 -5z m104 -287 c25 -74 46
-138 46 -143 0 -4 -45 -7 -100 -7 -55 0 -100 3 -100 8 0 20 96 290 101 284 4
-4 27 -68 53 -142z"/>
<path d="M3480 1120 c0 -68 -1 -70 -26 -70 -24 0 -25 -2 -22 -47 3 -40 6 -48
23 -48 19 0 20 -8 25 -170 6 -202 12 -217 97 -236 71 -16 83 -10 83 46 0 43
-1 45 -29 45 -48 0 -52 15 -49 171 l3 144 38 3 38 3 -3 42 c-3 41 -4 42 -40
45 l-38 3 0 70 0 69 -50 0 -50 0 0 -70z"/>
<path d="M2927 1043 c-14 -14 -7 -328 8 -379 23 -77 82 -124 155 -124 45 0 72
11 108 43 l22 20 0 -26 c0 -26 2 -27 50 -27 l50 0 0 251 0 250 -47 -3 -48 -3
-5 -183 c-5 -178 -6 -184 -29 -203 -14 -11 -39 -19 -63 -19 -93 0 -108 34
-108 250 l0 160 -43 0 c-24 0 -47 -3 -50 -7z"/>
<path d="M3880 1033 c-70 -32 -110 -117 -110 -233 1 -123 39 -203 114 -238
101 -48 215 -18 270 72 28 44 30 55 30 144 1 53 -3 114 -7 135 -10 45 -60 105
-104 123 -44 19 -150 17 -193 -3z m150 -86 c37 -18 60 -72 60 -142 0 -123 -28
-165 -111 -165 -37 0 -52 5 -73 26 -25 25 -26 32 -26 128 0 77 4 107 16 124
28 40 87 53 134 29z"/>
<path d="M2698 3 c1486 -2 3918 -2 5405 0 1486 1 270 2 -2703 2 -2973 0 -4189
-1 -2702 -2z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
